import React, { Fragment, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About",
    link: "/about",
  },
  {
    id: 3,
    title: "Luxury Aloha Apartments",
    link: "#",
    submenu: [
      {
        id: 6,
        title: "One Bedroom Apartments",
        link: "/one-bed-room",
        // submenu: [
        //   {
        //     id: 8,
        //     title: "Bedroom apartment with garden view",
        //     link: "/room",
        //   },
        //   {
        //     id: 9,
        //     title: "Bedroom apartment with ganga view",
        //     link: "/room",
        //   },
        // ],
      },
      {
        id: 7,
        title: "Two Bedroom Apartments",
        link: "/two-bed-room",
        // submenu: [
        //   {
        //     id: 10,
        //     title: "Bedroom luxury Apartment with ganga view",
        //     link: "/room",
        //   },
        //   {
        //     id: 11,
        //     title: "Bedroom Premium Luxury Apartment with ganga View",
        //     link: "/room",
        //   },
        //   {
        //     id: 12,
        //     title: "Bedroom Premium Luxury Apartment with ganga View",
        //     link: "/room",
        //   },
        // ],
      },
    ],
  },
  {
    id: 4,
    title: "Gallery",
    link: "/gallery",
  },
  {
    id: 5,
    title: "Contact",
    link: "/contact",
  },
];

const SubMenu = ({ submenu, clickHandler }) => {
    const [openIds, setOpenIds] = useState([]);

    const toggleSubMenu = (id) => {
      const index = openIds.indexOf(id);
      if (index !== -1) {
        setOpenIds(openIds.slice(0, index));
      } else {
        setOpenIds([...openIds, id]);
      }
    };

  return (
    <List className="subMenu">
      {submenu.map((item) => (
        <ListItem key={item.id}>
          {item.submenu ? (
           <Fragment>
            <p onClick={() => toggleSubMenu(item.id)}>
                    {item.title}
                    <i
                      className={
                        openIds.includes(item.id)
                          ? "bi bi-caret-up-fill"
                          : "bi bi-caret-down-fill"
                      }
                    ></i>
                  </p>
                  <Collapse
                  in={openIds.includes(item.id)}
                  timeout="auto"
                  unmountOnExit
                  >
                     <SubMenu
                      submenu={item.submenu}
                      clickHandler={clickHandler}
                    />
                  </Collapse>
           </Fragment>
          ) : (
            <Link
              onClick={clickHandler}
              className="active text-decoration-none"
              to={item.link}
            >
              {item.title}
            </Link>
          )}
        </ListItem>
      ))}
    </List>
  );
};

const MobileMenu = () => {
  // const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);
  const [openIds, setOpenIds] = useState([]);

  const toggleSubMenu = (id) => {
    const index = openIds.indexOf(id);
    if (index !== -1) {
      setOpenIds(openIds.slice(0, index));
    } else {
      setOpenIds([...openIds, id]);
    }
  };

  const clickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item) => (
            <ListItem
              className={openIds.includes(item.id) ? "active" : null}
              key={item.id}
            >
              {item.submenu ? (
                <Fragment>
                  <p onClick={() => toggleSubMenu(item.id)}>
                    {item.title}
                    <i
                      className={
                        openIds.includes(item.id)
                          ? "bi bi-caret-up-fill"
                          : "bi bi-caret-down-fill"
                      }
                    ></i>
                  </p>
                  <Collapse
                    in={openIds.includes(item.id)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <SubMenu
                      submenu={item.submenu}
                      clickHandler={clickHandler}
                    />
                  </Collapse>
                </Fragment>
              ) : (
                <Link className="active text-decoration-none" to={item.link}>
                  {item.title}
                </Link>
              )}
            </ListItem>
          ))}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
