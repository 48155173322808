import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import test4 from "../../images/testimonial/img-4.webp";

const Testimonials = [
  {
    tstImg: test4,
    name: "Mrs. Sunita Choudhary",
    title: "Photographer",
    descriptoion:
      "Staying at Aloha Luxury Apartments was an absolute delight! My family and I spent three wonderful nights here in October. The serene Ganga River view from our apartment was breathtaking. The luxury amenities and impeccable service made our holiday truly memorable. We enjoyed every moment of our stay, whether it was lounging by the pool or exploring the nearby attractions.The spacious and well-appointed apartment provided us with the perfect retreat after a day of sightseeing. We were impressed by the attention to detail and the friendly staff who went above and beyond to ensure our comfort. We cannot wait to return for another unforgettable experience. Highly recommended for anyone seeking a luxurious and rejuvenating getaway",
  },
  {
    tstImg: test4,
    name: "Mr. Satish Savorkar",
    title: "CEO Of Golden Bravo",
    descriptoion:
      "Our corporate retreat at Aloha Luxury Apartments exceeded all expectations! Mr. Manish Gehlot and I brought along 11 colleagues for a productive yet relaxing getaway. The spacious accommodations and state-of-the-art facilities were perfect for our team-building activities and brainstorming sessions. The breathtaking views of the Ganga River provided a serene backdrop for our discussions. The attentive staff ensured that every aspect of our stay was seamless, from arranging meeting spaces to catering to our dietary preferences.During our downtime, we enjoyed unwinding in the luxurious amenities, including the pool and fitness center. The convenient location near the riverfront added an extra touch of tranquility to our experience. Overall, Aloha Luxury Apartments provided the ideal setting for our corporate retreat, fostering both productivity and camaraderie among our team. We look forward to returning for future gatherings",
  },
];

const Testimonial = () => {
  
  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  return (
    <div className="wpo-testimonial-area">
      <div className="container">
        <div className="wpo-section-title-s2">
          <h2>What Client's Say?</h2>
        </div>
        <div className="wpo-testimonial-wrap">
          <div className="testimonial-slider">
            <Slider {...settings}>
              {Testimonials.map((tstml, tsm) => (
                <div className="wpo-testimonial-item" key={tsm}>
                  {/* <div className="wpo-testimonial-img">
                                        <img src={tstml.tstImg} alt="" />
                                    </div> */}
                  <div className="wpo-testimonial-content">
                    <p>{tstml.descriptoion}</p>
                    <div className="row">
                      <div className="col-auto">
                        <div class="author-avatar">
                          <img
                            src={tstml.tstImg}
                            alt={tstml.tstImg}
                            className="rounded circle"
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <h2>{tstml.name}</h2>
                        <span>Previous Client</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
