import React from "react";
import { Link } from "react-router-dom";
import abimg from "../../images/about.jpg";

const About2 = (props) => {

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                <img
                  src={abimg}
                  alt="about-img"
                  style={{
                    height: "480px",
                    backgroundSize: "cover",
                    "@media (max-width: 600px)": {
                      height: "280px",
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-content">
                <div className="about-title pb-3">
                  <span>ALOHA ON GANGES</span>

                </div>
                <div className="wpo-about-content-inner ">
                  <p>
                    Aloha on the ganges rishikesh, where your experience is everything. Luxury hotel in rishikesh.
                    In-house facilities are constantly add-on to provide an immersive experience.
                    Room decor, sweeping views, the restaurant and cuisine served,
                    and well-planned garden area detailing, ensure everyone is satisfied.
                    Aloha apartments rishikesh, by the Blissful Ganges are a better option for business travelers.
                    It includes seminar rooms and conference halls. Get pampered with airy and spacious rooms
                    with delicious morning meals, wellness treatments at the world-class spa, and water sports.
                    Aloha by Ganges is the Happy Hub for all ages,
                    where everyone can enjoy the package of coziness with the best of nature.
                  </p>

                  <Link
                    className="theme-btn text-decoration-none"
                    onClick={ClickHandler}
                    to="/enquiry"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About2;
