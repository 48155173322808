import { connect } from "react-redux";
import SectionTitleS2 from "../../components/SectionTitleS2";


const RoomsImageSection = () => {

    return (
        <>

            <div className="wpo-room-area section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-10 col-md-12">
                            <SectionTitleS2 MainTitle={'Aloha on the Ganges Resort & Private Apartment,in Rishikesh '} subTitle={""} />
                        </div>
                     
                    </div>
                </div>
            </div>


        </>
    )
};

export default connect(null)(RoomsImageSection);


