import { Link } from "react-router-dom";
import "./mobileFooter.css";



const MobileFooter = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <div className="mobile-footer">
            <Link 
            to={"/enquiry"}
            className="enquire-button text-decoration-none text-center">Enquire Now</Link>
            <a 
            href="tel:+91 9717177726"
            className="call-us-button text-decoration-none text-center">Call Us</a>
        </div>
    )
};

export default MobileFooter;


