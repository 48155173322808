import simg1 from '../images/room/7.webp'
import simg2 from '../images/room/2.webp'
import simg3 from '../images/room/3.webp'
import simg4 from '../images/room/5.webp'
import simg5 from '../images/room/9.webp'
import simg6 from '../images/room/4.webp'
// import simg7 from '../images/service/img-7.webp'

import singleImg1 from '../images/service-single/1.webp'
import singleImg2 from '../images/service-single/2.webp'
import singleImg3 from '../images/service-single/3.webp'
import singleImg4 from '../images/service-single/4.webp'
import singleImg5 from '../images/service-single/5.webp'
import singleImg6 from '../images/service-single/6.webp'
import singleImg7 from '../images/service-single/7.webp'






const Services = [
    {
        id: '1',
        fIcon:'bi-cake2-fill',
        title:'Restaurant',
        description:' Indulge in culinary delights at our on-site restaurant, offering a diverse menu of gourmet dishes crafted with fresh, locally sourced ingredients.',
        simg1:simg1,
        ssImg:singleImg1,
    },
    {
        id: '2',
        fIcon:'bi-p-circle',
        title:'Parking Area',
        description:'Our resort provides ample parking space for guests\' convenience, ensuring a stress-free arrival and departure experience.',
        simg1:simg2,
        ssImg:singleImg2,
    },
    {
        id: '3',
        fIcon:'bi-person-arms-up',
        title:'Swimming Pool',
        description:'Dive into relaxation in our pristine swimming pool, surrounded by lush tropical landscaping and equipped with loungers for ultimate comfort.',
        simg1:simg3,
        ssImg:singleImg3,
    },
    {
        id: '4',
        fIcon:'bi-border-width',
        title:'Fully furnished Apartment',
        description:' Experience the epitome of comfort and luxury in our fully furnished apartments, featuring modern amenities and stylish décor for a memorable stay.',
        simg1:simg4,
        ssImg:singleImg4,
    },
    {
        id: '5',
        fIcon:'bi-tv',
        title:'Television',
        description:'Stay entertained and informed with a wide selection of channels on our high-definition televisions, perfect for unwinding after a day of exploration.',
        simg1:simg5,
        ssImg:singleImg5,
    },
    {
        id: '6',
        fIcon:'bi-wifi',
        title:'Free Wifi',
        description:'Stay connected with complimentary high-speed Wi-Fi throughout the resort, allowing you to effortlessly browse the web, check emails, or share your vacation memories on social media.',
        simg1:simg6,
        ssImg:singleImg6,
    },
    {
        id: '7',
        fIcon:'bi-backpack4',
        title:'Refrigerator',
        description:'Keep your favorite snacks and beverages fresh in the convenience of your room with our in-room refrigerators, providing added comfort and convenience during your stay.',
        simg1:simg2,
        ssImg:singleImg7,
    },
    {
        id: '8',
        fIcon:'bi-cup-hot',
        title:'Tea / Coffee maker',
        description:'Start your day right with a freshly brewed cup of coffee or tea, courtesy of the in-room tea and coffee maker, ensuring a refreshing start to your mornings.',
        simg1:simg1,
        ssImg:singleImg1,
    }
]
export default Services;
