import React from "react";

const SectionTitleS2 = (props) => {
  return (
    <div className="wpo-section-title-s2">
      <h2
     
      >{props.MainTitle}</h2>
      <p
      >
        {props.subTitle}
      </p>
    </div>
  );
};

export default SectionTitleS2;
