import proImg from '../images/room/ganga/1.png'
import simg3 from '../images/room/ganga/5.png'
import simg4 from '../images/room/ganga/6.png'
import simg5 from '../images/room/ganga/7.png'
import simg6 from '../images/room/ganga/2.png'
import simg7 from '../images/room/ganga/3.png'

import singleImg1 from '../images/room/ganga/1.png'
import singleImg2 from '../images/room/ganga/4.png'
import singleImg3 from '../images/room/ganga/5.png'
import singleImg4 from '../images/room/ganga/6.png'
import singleImg5 from '../images/room/ganga/7.png'
import singleImg6 from '../images/room/ganga/2.png'
import singleImg7 from '../images/room/ganga/8.png'






const roomsimages = [
    {
        id: 1,
        fIcon:'flaticon-food-tray',
        title:'1 Bedroom Luxury Apartment Garden View',
        description:'The One-Deluxe apartments at our resort in Rishikesh are perfect for both business and leisure travellers. You will be provided 1 room, kitchen, spacious living area which are fully furnished , blend comfort and a dedicated caretaker.',
         proImg:simg4,
        ssImg:singleImg1,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 2,
        fIcon:'flaticon-parking',
        title:'2 Bedroom Luxury Apartment Ganga view',
        description:'The One-Deluxe apartments at our resort in Rishikesh are perfect for both business and leisure travellers. You will be provided 1 room, kitchen, spacious living area which are fully furnished , blend comfort and a dedicated caretaker.',
        proImg:simg5,
        ssImg:singleImg2,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 3,
        fIcon:'flaticon-swimmer',
        title:'2 Bedroom Ultra Luxury Apartment Ganga view',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        proImg:simg3,
        ssImg:singleImg3,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 4,
        fIcon:'flaticon-mortar',
        title:' Premium Luxury Apartment ganga View',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        proImg:simg4,
        ssImg:singleImg4,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 5,
        fIcon:'flaticon-dumbell',
        title:'Ultra Luxury Apartment Ganga View',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        proImg:simg5,
        ssImg:singleImg5,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 6,
        fIcon:'flaticon-wifi',
        title:'Free Wifi',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        proImg:simg6,
        ssImg:singleImg6,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 7,
        fIcon:'flaticon-cappuccino',
        title:'Breakfast',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        proImg:simg7,
        ssImg:singleImg7,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 8,
        fIcon:'flaticon-more',
        title:'Other Service',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        proImg:proImg,
        ssImg:singleImg1,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 9,
        fIcon:'flaticon-more',
        title:'apartment garden view',
        description:'The One-Deluxe apartments at our resort in Rishikesh are perfect for both business and leisure travellers. You will be provided 1 room, kitchen, spacious living area which are fully furnished , blend comfort and a dedicated caretaker.',
        proImg:proImg,
        ssImg:singleImg1,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    },
    {
        id: 10,
        fIcon:'flaticon-more',
        title:'apartment ganga view',
        description:'The One-Deluxe apartments at our resort in Rishikesh are perfect for both business and leisure travellers. You will be provided 1 room, kitchen, spacious living area which are fully furnished , blend comfort and a dedicated caretaker.',
        proImg:proImg,
        ssImg:singleImg1,
        price:200,
        sqm:32,
        bedroom:3,
        bathroom:2
    }
]
export default roomsimages;
